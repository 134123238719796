import { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const GenericCell = ({ cellValue }) => (
  <>
    <TableCell
      scope="row"
      sx={{
        paddingRight: 0,
        color: 'inherit',
      }}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography>{cellValue}</Typography>
      </Stack>
    </TableCell>
  </>
);

export default memo(GenericCell);
