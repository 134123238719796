import { memo } from 'react';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#87C8E0',
    marginTop: theme.spacing(5),
  },
}));

const StyledTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
  color: '#DDDDDD',
  fontSize: theme.typography.pxToRem(14),
  lineHeight: '24px',
  fontWeight: 500,
  padding: '0 16px',
  '&.Mui-selected': {
    color: '#87C8E0',
  },
}));

const Tabs = ({ tabs, value, onChange }) => (
  <StyledTabs
    value={value}
    onChange={onChange}
    TabIndicatorProps={{
      sx: {
        backgroundColor: '#87C8E0',
      },
    }}>
    {tabs.map(tab => (
      <StyledTab label={tab.label} value={tab.value} />
    ))}
  </StyledTabs>
);

export default memo(Tabs);
