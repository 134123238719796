import { memo, useCallback, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { TableCellTypography } from '../GenericTableCell';
import useTypographyMediaQuery from '../../hooks/useTypographyMediaQuery';
import { formatDate } from '../../utils/formatTime';
import { mapCellValue } from '../../utils/table.util';

const StyledWrapper = styled(TableCellTypography, {
  shouldForwardProp: prop =>
    prop !== 'mediumScreen' && prop !== 'largeScreen' && prop !== 'smallScreen' && prop !== 'width',
})(({ theme, largeScreen, mediumScreen, smallScreen, width }) => ({
  textTransform: 'capitalize',
  width,
  ...(largeScreen
    ? {
        fontSize: theme.typography.pxToRem(10),
      }
    : {}),
  ...(mediumScreen
    ? {
        fontSize: theme.typography.pxToRem(8),
      }
    : {}),
  ...(smallScreen
    ? {
        fontSize: theme.typography.pxToRem(8),
      }
    : {}),
}));

const YesNoCell = ({ firstCellValue, secondCellValue, firstCellDate, secondCellDate }) => {
  const { mediumScreen, largeScreen, smallScreen } = useTypographyMediaQuery();
  const firstCellFormatedDate = useMemo(() => formatDate(firstCellDate), [firstCellDate]);
  const secondCellFormatedDate = useMemo(() => formatDate(secondCellDate), [secondCellDate]);
  const [openTooltips, setOpenTooltips] = useState({
    firstCellTooltip: false,
    secondCellTooltip: false,
  });

  const handleTooltipClose = useCallback(
    tooltipName => {
      setOpenTooltips(prevState => ({
        ...prevState,
        [tooltipName]: false,
      }));
    },
    [setOpenTooltips],
  );

  const handleTooltipOpen = useCallback(
    tooltipName => {
      setOpenTooltips(prevState => ({
        ...prevState,
        [tooltipName]: true,
      }));
    },
    [setOpenTooltips],
  );

  return (
    <TableCell
      sx={{
        padding: '16px 10px',
        color: 'inherit',
        ...(mediumScreen || largeScreen
          ? {
              padding: '16px 30px 16px 10px',
            }
          : {}),
      }}>
      <Stack direction="row" justifyContent="space-between" gap={1}>
        <StyledWrapper
          width="9ch"
          mediumScreen={mediumScreen}
          largeScreen={largeScreen}
          smallScreen={smallScreen}>
          <Tooltip
            placement="top"
            onClose={() => handleTooltipClose('firstCellTooltip')}
            open={openTooltips.firstCellTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={firstCellFormatedDate}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#3F3F3F',
                },
              },
            }}>
            <TableCellTypography
              onClick={() => handleTooltipOpen('firstCellTooltip')}
              onMouseLeave={() => handleTooltipClose('firstCellTooltip')}
              sx={
                firstCellFormatedDate
                  ? {
                      cursor: 'pointer',
                    }
                  : {}
              }>
              {mapCellValue(firstCellValue)}
            </TableCellTypography>
          </Tooltip>
        </StyledWrapper>
        <StyledWrapper
          width="12ch"
          mediumScreen={mediumScreen}
          largeScreen={largeScreen}
          smallScreen={smallScreen}>
          <ClickAwayListener onClickAway={() => handleTooltipClose('secondCellTooltip')}>
            <Tooltip
              placement="top"
              onClose={() => handleTooltipClose('secondCellTooltip')}
              open={openTooltips.secondCellTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={secondCellFormatedDate}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#3F3F3F',
                  },
                },
              }}>
              <TableCellTypography
                onClick={() => handleTooltipOpen('secondCellTooltip')}
                onMouseLeave={() => handleTooltipClose('secondCellTooltip')}
                sx={
                  secondCellFormatedDate
                    ? {
                        cursor: 'pointer',
                      }
                    : {}
                }>
                {mapCellValue(secondCellValue)}
              </TableCellTypography>
            </Tooltip>
          </ClickAwayListener>
        </StyledWrapper>
      </Stack>
    </TableCell>
  );
};

export default memo(YesNoCell);
