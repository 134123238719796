import { memo } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextFieled from '@mui/material/TextField';
import Iconify from './Iconify';
import IconButton from './IconButton';

const StyledInput = styled(TextFieled)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#4B4B4B',
    borderRadius: '8px 8px 0px 0px',
    padding: '16px 24px',
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiOutlinedInput-root input': {
    padding: 0,
  },
});

const SearchInput = ({
  searchValue,
  onChangeSearchValue,
  onResetSearch,
  hasEndAdornment,
  placeholder,
  disabled,
}) => (
  <StyledInput
    disabled={disabled}
    InputProps={{
      disableUnderline: true,
      startAdornment: (
        <InputAdornment position="start">
          <Iconify icon="material-symbols:search" color="#6B7280" width={20} height={20} />
        </InputAdornment>
      ),
      ...(hasEndAdornment
        ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onResetSearch}>
                  <Iconify icon="ic:outline-close" color="#6B7280" width={20} height={20} />
                </IconButton>
              </InputAdornment>
            ),
          }
        : {}),
    }}
    value={searchValue}
    onChange={onChangeSearchValue}
    fullWidth
    placeholder={placeholder}
  />
);

export default memo(SearchInput);
