import { useQuery } from 'react-query';
import { ENVIRONEMNTS_QUERY_KEY } from '../../constants/query';
import CustomerService from '../../services/customer.service';

const useEnvironments = ({ customerId, page = 1, rowsPerPage = 5 }) => {
  return useQuery(
    !!customerId &&
      !!page &&
      !!rowsPerPage && [ENVIRONEMNTS_QUERY_KEY, customerId, page, rowsPerPage],
    async () =>
      CustomerService.getCustomerEnvironments({
        customerId,
        page,
        limit: rowsPerPage,
      }),
    {
      refetchOnReconnect: true,
    },
  );
};

export default useEnvironments;
