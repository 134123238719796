import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { CUSTOMER_QUERY_KEY, ENVIRONEMNTS_QUERY_KEY } from '../../constants/query';
import environmentService from '../../services/environment.service';
import useQueryParams from '../useQueryParams';
import useQueryData from '../useQueryData';
import { customerIdSelector } from '../../selectors/customer';

const useUpdateEnvironments = () => {
  const queryClient = useQueryClient();
  const customerId = useQueryData(CUSTOMER_QUERY_KEY, customerIdSelector);
  const { enqueueSnackbar } = useSnackbar();
  const {
    queryObject: { page = 1, rowsPerPage = 5 },
  } = useQueryParams({ defaultRowsPerPage: 5 });

  return useMutation({
    mutationFn: values =>
      environmentService.editEnvironment({
        customerEnvId: values.id,
        configManagerSupportId: values.configManagerSupportId,
        description: values.description,
        location: values.location,
        name: values.name,
      }),
    onMutate: async values => {
      await queryClient.cancelQueries({
        queryKey: [ENVIRONEMNTS_QUERY_KEY, page, rowsPerPage],
      });

      const previousEnvironments = queryClient.getQueryData([
        ENVIRONEMNTS_QUERY_KEY,
        customerId,
        page,
        rowsPerPage,
      ]);
      queryClient.setQueryData(
        [ENVIRONEMNTS_QUERY_KEY, customerId, page, rowsPerPage],
        prevData => ({
          ...prevData,
          environments: prevData.environments.map(env =>
            env.id === values.id ? { ...env, ...values } : env,
          ),
        }),
      );

      return { previousEnvironments };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        [ENVIRONEMNTS_QUERY_KEY, customerId, page, rowsPerPage],
        context.previousEnvironments,
      );

      enqueueSnackbar(err, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
    onSuccess: () => {
      enqueueSnackbar('Environment updated successfully', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
  });
};

export default useUpdateEnvironments;
