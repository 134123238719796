import api from './api';

const getApplications = ({ customerEnvironmentId, query }) =>
  api.get(`/customerEnvironment/${customerEnvironmentId}/applications${query}`);

const getApplicationVersions = ({ applicationId, page, limit }) =>
  api.get(`/environmentApplication/${applicationId}/versions`, {
    params: {
      Page: page,
      Limit: limit,
    },
  });

const getSecurityApplications = ({ customerEnvironmentId, query }) =>
  api.get(`/customerEnvironment/${customerEnvironmentId}/securities${query}`);

const getSecurityVersions = ({ applicationId, page, limit }) =>
  api.get(`/environmentApplication/${applicationId}/security-versions`, {
    params: {
      Page: page,
      Limit: limit,
    },
  });

const getSecurityDownloadUrl = ({ resourceId, key }) =>
  api.get(`/application/${resourceId}/resource/${key}`);

const ApplicationsService = {
  getApplications,
  getApplicationVersions,
  getSecurityApplications,
  getSecurityVersions,
  getSecurityDownloadUrl,
};

export default ApplicationsService;
