import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { initialTabs } from './constants';
import TabsPageLayout from '../../components/TabsPageLayout';
import { PORTAL_ROUTE } from '../../constants/routes';
import useQueryData from '../../hooks/useQueryData';
import { CUSTOMER_QUERY_KEY, CUSTOMERS_QUERY_KEY, USER_QUERY_KEY } from '../../constants/query';
import { userRoleSelector } from '../../selectors/user';
import { Roles } from '../../constants/role';
import { customerIdSelector } from '../../selectors/customer';
import { customersSelectors } from '../../selectors/customers';

const Company = () => {
  const role = useQueryData(USER_QUERY_KEY, userRoleSelector);
  const isSuperAdmin = role === Roles.superAdmin;
  const location = useLocation();
  const isCreateCompanyRoute = location.pathname === `${PORTAL_ROUTE}/company/create`;
  const navigate = useNavigate();
  const customerId = useQueryData(CUSTOMER_QUERY_KEY, customerIdSelector);
  const customers = useQueryData(CUSTOMERS_QUERY_KEY, customersSelectors);

  const companyTabs = useMemo(
    () => [
      isCreateCompanyRoute && isSuperAdmin
        ? {
            label: 'Company Details',
            value: `${PORTAL_ROUTE}/company/create`,
          }
        : {
            label: 'Company Details',
            value: `${PORTAL_ROUTE}/company/edit`,
          },
      ...initialTabs,
    ],
    [isSuperAdmin, isCreateCompanyRoute],
  );

  useEffect(() => {
    if (!customerId && !customers?.length && location?.state?.from === '/login') {
      navigate(`${PORTAL_ROUTE}/companies`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, customers?.length]);

  useEffect(() => {
    if (customerId && !customers?.length) {
      navigate(`${PORTAL_ROUTE}/companies`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, customers?.length, location.pathname]);

  return <TabsPageLayout tabs={companyTabs} />;
};

export default Company;
