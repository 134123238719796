import { memo } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Iconify from '../Iconify';
import { colors } from '../../theme/palette/darkPalette';

const StyledFilterField = styled(TextField)({
  width: '100%',
  padding: '6.5px 0',
  '& label': {
    color: '#fff',
    '&.Mui-focused': {
      color: '#fff',
      '& .MuiInput-underline:before': {
        borderColor: '#D1D5DB',
      },
    },
  },
  '& .MuiInput-underline:after': {
    borderColor: colors.steelBlue,
  },
  '& .MuiInputBase-root': {
    '& fieldset': {
      borderColor: colors.steelBlue,
    },
  },
  '& .MuiInputBase-input': {
    padding: '6.5px 0',
  },
  '& svg': {
    color: '#ddd',
  },
});

const FilterField = ({
  source,
  label,
  selectedValue,
  onChangeFilter,
  select,
  id,
  children,
  fieldIndex,
  sectionIndex,
  disabled,
}) => (
  <StyledFilterField
    id={id}
    label={label}
    select={select}
    disabled={disabled}
    {...(select
      ? {
          SelectProps: {
            IconComponent: props => (
              <Iconify
                {...props}
                icon="iconamoon:arrow-down-2-duotone"
                color="#ddd"
                width={24}
                height={24}
              />
            ),
            MenuProps: {
              sx: {
                '& .MuiPaper-root': {
                  borderRadius: 0,
                  backgroundColor: '#2D2D2D',
                  boxShadow: 'unset',
                  '& .MuiList-root': {
                    paddingTop: 0,
                  },
                },
              },
            },
          },
        }
      : {})}
    variant="standard"
    value={selectedValue}
    onChange={event =>
      onChangeFilter({
        source,
        fieldIndex,
        sectionIndex,
        fieldValue: event.target.value,
      })
    }>
    {children}
  </StyledFilterField>
);

export default memo(FilterField);
