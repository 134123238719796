import { useCallback, useMemo, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PasswordFieldAdornment from '../../../components/PasswordFieldAdornment';

const StyledTextField = styled(TextField, {
  shouldForwardProp: prop => prop !== 'isReadyOnly',
})(({ isReadyOnly }) => ({
  width: '100%',
  '& label.Mui-focused': {
    color: '#fff',
  },
  '& .MuiInput-underline:after': {
    borderColor: '#D1D5DB',
  },
  '& .MuiInputBase-root': {
    '& fieldset': {
      borderStyle: isReadyOnly ? 'dotted' : 'solid',
      borderColor: '#D1D5DB',
    },
  },
  '& .MuiInputBase-root.Mui-focused': {
    '& fieldset': {
      borderStyle: isReadyOnly ? 'dotted' : 'solid',
      borderColor: '#D1D5DB',
    },
  },
  '& .MuiFormHelperText-root': {
    color: '#fff',
    '&.Mui-error': {
      color: '#FF4842',
    },
  },
  '& .MuiInputLabel-root': {
    lineHeight: '20px',
  },
}));

const GeneralTextField = ({
  readOnly = false,
  label,
  type = 'text',
  defaultValue = null,
  error = false,
  helperText = '',
  showEditButton = true,
  FieldComponent,
  endAdornment = null,
  variant = 'outlined',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const textFieldRef = useRef(null);

  const onToggleShowPassword = useCallback(() => setShowPassword(prev => !prev), [setShowPassword]);

  const textFieldType = useMemo(() => {
    if (type !== 'password') return type;

    return showPassword ? 'text' : 'password';
  }, [type, showPassword]);

  return (
    <Stack direction="row" alignItems="baseline" gap={2}>
      <StyledTextField
        {...props}
        {...(!!defaultValue
          ? {
              defaultValue,
            }
          : {})}
        defaultValue={defaultValue}
        inputRef={textFieldRef}
        label={label}
        type={textFieldType}
        fullWidth
        variant={variant}
        error={error}
        helperText={helperText}
        InputProps={{
          autoComplete: 'new-password',
          sx: {
            '& input:-webkit-autofill': {
              WebkitBoxShadow: 'unset',
            },
          },
          readOnly,
          ...(type === 'password'
            ? {
                endAdornment: (
                  <PasswordFieldAdornment
                    onClick={onToggleShowPassword}
                    showPassword={showPassword}
                  />
                ),
              }
            : {}),
          ...(!!endAdornment ? { endAdornment } : {}),
        }}
        InputLabelProps={{
          sx: {
            fontSize: '16px',
            fontWeight: 400,
            color: '#fff',
            lineHeight: '14px',
          },
        }}
        isReadyOnly={readOnly}
      />
      {!!FieldComponent ? (
        <FieldComponent />
      ) : (
        (!readOnly || !showEditButton) && <Box width={70} height={40} />
      )}
    </Stack>
  );
};

export default GeneralTextField;
