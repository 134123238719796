import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { CUSTOMER_QUERY_KEY, ENVIRONEMNTS_QUERY_KEY } from '../../constants/query';
import environmentService from '../../services/environment.service';
import useQueryParams from '../useQueryParams';
import useQueryData from '../useQueryData';
import { customerIdSelector } from '../../selectors/customer';

const useCreateEnvironment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const customerId = useQueryData(CUSTOMER_QUERY_KEY, customerIdSelector);

  const {
    queryObject: { page = 1, rowsPerPage = 5 },
  } = useQueryParams({ defaultRowsPerPage: 5 });

  return useMutation({
    mutationFn: values =>
      environmentService.addEnvironment({
        name: values.name,
        configManagerSupportId: values.configManagerSupportId,
        description: values.description,
        location: values.location,
        customerId,
      }),
    onSuccess: () => {
      queryClient.refetchQueries([ENVIRONEMNTS_QUERY_KEY, customerId, page, rowsPerPage]);

      enqueueSnackbar('Environment created successfully', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
  });
};

export default useCreateEnvironment;
