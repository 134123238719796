import { memo, useMemo, useState, useCallback } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import GeneralTextField from '../../profile/general/GeneralTextField';
import Iconify from '../../../components/Iconify';
import useUpdateEnvironments from '../../../hooks/enviornment/useUpdateEnvironments';
import useCreateEnvironment from '../../../hooks/enviornment/useCreateEnvironment';
import ErrorAlert from '../../profile/general/ErrorAlert';
import { pagesText } from '../../../constants/pagesText';
import { useQueryClient } from 'react-query';
import { USER_QUERY_KEY } from '../../../constants/query';
import useQueryData from '../../../hooks/useQueryData';
import { activeCustomerEnvIdSelector } from '../../../selectors/user';
import Button from '../../../components/Button';

const EnvironmentSchema = Yup.object({
  name: Yup.string().trim().required('Environment name is required'),
  configManagerSupportId: Yup.string()
    .trim()
    .test('len', 'Support id must be 44 characters', val => val?.length === 44)
    .required('Environment supportId is required'),
});

const EndAdornment = memo(() => (
  <InputAdornment position="end">
    <Iconify icon="mdi:required-circle" color="#DE312B" width={16} height={16} />
  </InputAdornment>
));

const MatchField = memo(() => (
  <Box width={70}>
    <Iconify icon="ic:round-check" color="#7DFF92" width={20} height={20} />
  </Box>
));

const EnvironmentForm = ({
  onExitAddMode,
  isEditMode,
  name = '',
  configManagerSupportId = '',
  description = '',
  location = '',
  editEnvironmentId,
  onClose,
}) => {
  const queryClient = useQueryClient();
  const customerEnvId = useQueryData(USER_QUERY_KEY, activeCustomerEnvIdSelector);

  const updateEnvironmentMutation = useUpdateEnvironments({
    isEditMode,
  });
  const createEnvironmentMutation = useCreateEnvironment();

  const [serverError, setServerError] = useState('');
  // Todo: we need to handle this error in a better way when we have separate states for each error field on backend
  const isNameFieldInValid = useMemo(() => {
    if (!serverError) return false;

    const nameRegex = /name/gi;

    return nameRegex.test(serverError);
  }, [serverError]);
  // Todo: we need to handle this error in a better way when we have separate states for each error field on backend
  const isSupportIdFieldInValid = useMemo(() => {
    if (!serverError) return false;

    const supportIdRegex = /supportid | support id/gi;

    return supportIdRegex.test(serverError);
  }, [serverError]);

  const isSupportIdNameExists = useMemo(() => {
    if (!serverError) return false;

    const supportIdRegex = /somesupportidname/gi;

    return supportIdRegex.test(serverError);
  }, [serverError]);

  const formik = useFormik({
    initialValues: {
      name,
      configManagerSupportId,
      description,
      location,
    },
    validationSchema: EnvironmentSchema,
    onSubmit: (values, { resetForm }) => {
      if (isEditMode) {
        updateEnvironmentMutation.mutate(
          {
            id: editEnvironmentId,
            ...values,
          },
          {
            onSuccess: () => {
              onExitAddMode();
              resetForm();
              setServerError('');
            },
            onError: error => {
              setServerError(error);
            },
          },
        );

        onClose();
        return;
      }

      createEnvironmentMutation.mutate(values, {
        onSuccess: data => {
          onExitAddMode();
          resetForm();
          setServerError('');
          onClose();

          if (!customerEnvId) {
            queryClient.setQueryData(USER_QUERY_KEY, prevProps => ({
              ...prevProps,
              defaultCustomerEnvId: data.id,
            }));
          }
        },
        onError: error => {
          setServerError(error);
        },
      });
    },
    validateOnChange: false,
    enableReinitialize: isEditMode,
  });

  const checkShowEndAdornment = useCallback(
    field => {
      if (isEditMode) return false;

      const noErrorTouched =
        (!formik.errors || !formik.errors[field]) && formik.touched && !formik.touched[field];
      const errorNotTouched = !!formik.errors[field] && !formik.touched[field];

      return noErrorTouched || errorNotTouched;
    },
    [formik.errors, formik.touched, isEditMode],
  );

  const nameFields = useMemo(() => formik.getFieldProps('name'), [formik]);
  const supportIdFields = useMemo(() => formik.getFieldProps('configManagerSupportId'), [formik]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
        <Stack gap={3} pt={1} height="100%">
          <Stack justifyContent="space-between" gap={2} height="100%">
            <Stack gap={3} pt={1}>
              <GeneralTextField
                label="Name"
                error={(!!formik.errors.name && !!formik.touched.name) || isNameFieldInValid}
                helperText={!!formik.touched.name && formik.errors.name}
                endAdornment={
                  checkShowEndAdornment('name') && !nameFields.value ? <EndAdornment /> : null
                }
                FieldComponent={
                  !formik.errors.name && !!nameFields.value && !isNameFieldInValid
                    ? MatchField
                    : null
                }
                {...nameFields}
              />
              {isNameFieldInValid && (
                <ErrorAlert
                  title="Environment Name Already Exists"
                  description="Please pick a different name"
                />
              )}
              <GeneralTextField
                label="Support ID"
                error={
                  (!!formik.errors.configManagerSupportId &&
                    !!formik.touched.configManagerSupportId) ||
                  isSupportIdFieldInValid
                }
                helperText={
                  (!!formik.touched.configManagerSupportId &&
                    formik.errors.configManagerSupportId) ||
                  (!isSupportIdFieldInValid && pagesText.environments.supportIdInfoText)
                }
                endAdornment={
                  checkShowEndAdornment('configManagerSupportId') && !supportIdFields.value ? (
                    <EndAdornment />
                  ) : null
                }
                FieldComponent={
                  !formik.errors.configManagerSupportId &&
                  !!supportIdFields.value &&
                  !isSupportIdFieldInValid
                    ? MatchField
                    : null
                }
                {...supportIdFields}
              />
              {isSupportIdFieldInValid && (
                <ErrorAlert
                  title="SupportID already exists."
                  description="Please check for your Support ID"
                />
              )}
              {isSupportIdNameExists && (
                <ErrorAlert description="Support Id Name cannot be changed as the Application Catalog has been created" />
              )}
              <GeneralTextField
                variant="standard"
                label="Description"
                {...formik.getFieldProps('description')}
              />
              <GeneralTextField
                variant="standard"
                label="Location"
                {...formik.getFieldProps('location')}
              />
            </Stack>
            <Stack direction="row" gap={1.75} justifyContent="end">
              <Button
                variant="text"
                onClick={onClose}
                styles={{
                  color: '#B5B5B5',
                  textTransform: 'capitalize',
                }}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                type="submit"
                styles={{
                  textTransform: 'capitalize',
                }}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default memo(EnvironmentForm);
