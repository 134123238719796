import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Scrollbar = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'maxHeight' && prop !== 'height' && prop !== 'scrollbarThickness',
})(({ height = '100%', maxHeight = '100%', scrollbarThickness = '6px', styles }) => ({
  overflow: 'auto',
  height,
  maxHeight,
  ...styles,
  '&::-webkit-scrollbar': {
    width: scrollbarThickness,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#4B4B4B',
    borderRadius: '16px',
  },
}));

export default Scrollbar;
