import { useCallback, useState } from 'react';
import IconButton from '../IconButton';
import Iconify from '../Iconify';
import { Menu, MenuItem } from '../index';
import { pagesText } from '../../constants/pagesText';
import useActiveRoute from '../../hooks/useActiveRoute';
import { PORTAL_ROUTE } from '../../constants/routes';

const TableRowActions = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { checkIsActiveRoute } = useActiveRoute();
  const onMenuOpen = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <>
      <IconButton
        aria-label="expand row"
        onClick={onMenuOpen}
        selected={!!anchorEl}
        color="#c5c5c5">
        <Iconify icon="charm:menu-kebab" color="#c5c5c5" width={22} height={22} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={onClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        styles={{
          boxShadow:
            '0px 4px 6px 0px rgba(100, 116, 139, 0.12), 0px 2px 4px 0px rgba(31, 41, 55, 0.06)',
        }}>
        <MenuItem
          selected={checkIsActiveRoute(`${PORTAL_ROUTE}/apps`)}
          onClick={onClose}
          icon="ci:info"
          text={pagesText.tableExpandedRowActions.info}
        />
        <MenuItem
          selected={checkIsActiveRoute(`${PORTAL_ROUTE}/security`)}
          onClick={onClose}
          icon="mdi:security-lock-outline"
          text={pagesText.tableExpandedRowActions.security}
        />
        <MenuItem
          selected={checkIsActiveRoute(`${PORTAL_ROUTE}/license`)}
          onClick={onClose}
          icon="mingcute:badge-line"
          text={pagesText.tableExpandedRowActions.license}
        />
        <MenuItem
          selected={checkIsActiveRoute(`${PORTAL_ROUTE}/actions`)}
          onClick={onClose}
          icon="quill:checkmark-double"
          text={pagesText.tableExpandedRowActions.actions}
        />
      </Menu>
    </>
  );
};

export default TableRowActions;
