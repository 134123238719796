import { memo } from 'react';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';

const NoDataCell = ({ children, colSpan = 10 }) => (
  <TableCell colSpan={colSpan}>
    <Stack justifyContent="center" alignItems="center">
      <Typography
        sx={{
          color: '#fff',
        }}>
        {children}
      </Typography>
    </Stack>
  </TableCell>
);

export default memo(NoDataCell);
