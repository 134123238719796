import { ListItemText, Menu, MenuItem } from './index';
import { pagesText } from '../constants/pagesText';

const DownloadApplicationsMenu = ({
  anchorEl,
  onClose,
  onDownloadSecurityCatalog,
  onDownloadSecurityAllCatalog,
  originStyles,
}) => (
  <Menu
    anchorEl={anchorEl}
    onClose={onClose}
    transformOrigin={originStyles.transformOrigin}
    anchorOrigin={originStyles.anchorOrigin}
    styles={{
      maxWidth: 175,
      boxShadow: 'unset',
    }}
    sx={{
      '& .MuiPaper-root': {
        marginTop: 0,
      },
    }}>
    <MenuItem
      onClick={() => onDownloadSecurityCatalog('securityCatalog')}
      icon="material-symbols:download"
      styles={{
        padding: '8px',
        whiteSpace: 'normal',
      }}>
      <ListItemText
        primary={pagesText.securityExpandedRowActions.securityCatalog}
        primaryStyles={{
          fontSize: 12,
          lineHeight: '30px',
          fontWeight: 400,
          color: '#fff',
        }}
      />
    </MenuItem>
    <MenuItem
      onClick={() => onDownloadSecurityCatalog('wdacPolicyWithFallback')}
      icon="material-symbols:download"
      styles={{
        padding: '8px',
        whiteSpace: 'normal',
      }}>
      <ListItemText
        primary={pagesText.securityExpandedRowActions.securityPolicyWithFallback}
        primaryStyles={{
          fontSize: 12,
          lineHeight: '30px',
          fontWeight: 400,
          color: '#fff',
        }}
      />
    </MenuItem>
    <MenuItem
      onClick={() => onDownloadSecurityCatalog('wdacPolicyWithoutFallback')}
      icon="material-symbols:download"
      styles={{
        padding: '8px',
        whiteSpace: 'normal',
      }}>
      <ListItemText
        primary={pagesText.securityExpandedRowActions.securityPolicyWithoutFallback}
        primaryStyles={{
          fontSize: 12,
          lineHeight: '30px',
          fontWeight: 400,
          color: '#fff',
        }}
      />
    </MenuItem>
    <MenuItem
      onClick={onDownloadSecurityAllCatalog}
      icon="material-symbols:download"
      styles={{
        padding: '8px',
        whiteSpace: 'normal',
      }}>
      <ListItemText
        primary={pagesText.securityExpandedRowActions.all}
        primaryStyles={{
          fontSize: 12,
          lineHeight: '30px',
          fontWeight: 400,
          color: '#fff',
        }}
      />
    </MenuItem>
  </Menu>
);

export default DownloadApplicationsMenu;
