import { pagesText } from '../../../constants/pagesText';

export const headings = [
  {
    title: pagesText.environments.environmentsTable.headings.environmentName,
    name: 'name',
  },
  {
    title: pagesText.environments.environmentsTable.headings.description,
    name: 'description',
  },
  {
    title: pagesText.environments.environmentsTable.headings.supportId,
    name: 'configManagerSupportId',
  },
];

export const generateColumnData = description => {
  return [
    {
      name: 'name',
      styles: {
        wordBreak: 'break-all',
      },
    },
    {
      name: 'description',
      styles: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '200px',
      },
      tooltipText: description,
    },
    {
      name: 'configManagerSupportId',
      styles: {
        wordBreak: 'break-all',
      },
    },
  ];
};
