import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useInView } from 'react-intersection-observer';
import TableRow from '@mui/material/TableRow';
import TableExpandedRow from './TableExpandedRow';
import TableAllVersionsRow from './TableAllVersionsRow';
import { APPLICATION_VERSIONS_QUERY_KEY } from '../../constants/query';
import applicationsService from '../../services/applications.service';
import LoadingRow from './LoadingRow';
import NoDataCell from '../NoDataCell';
import { pagesText } from '../../constants/pagesText';

const LIMIT = 5;

const ExpandedRow = memo(({ source, applicationId, activePage, columnsOrder }) => {
  const [showAllVersions, setShowAllVersions] = useState(false);

  const { ref: lastElementRef, inView } = useInView();
  const queryClient = useQueryClient();

  const { data, error, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    [APPLICATION_VERSIONS_QUERY_KEY, applicationId, source],
    async ({ pageParam = 1 }) => {
      const offset = (pageParam - 1) * LIMIT;
      const getApplicationVersions =
        source === 'security'
          ? applicationsService.getSecurityVersions
          : applicationsService.getApplicationVersions;
      const data = await getApplicationVersions({
        applicationId,
        // TODO: currently page query not working
        page: 1,
        limit: LIMIT,
        offset,
      });

      return {
        results: data?.versions || [],
        count: data?.count || 0,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const flatAllPages = allPages.flatMap(page => page.results);
        if (flatAllPages.length + LIMIT <= lastPage.count && showAllVersions) {
          return flatAllPages.length / LIMIT + 1;
        }
        return undefined;
      },
    },
  );

  useEffect(() => {
    return () => {
      queryClient.removeQueries([APPLICATION_VERSIONS_QUERY_KEY, applicationId]);
    };
  }, [queryClient, applicationId]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const onAllVersionsClick = useCallback(() => {
    setShowAllVersions(true);
  }, [setShowAllVersions]);

  const versionsData = useMemo(
    () => ({
      versions: data?.pages.flatMap(page => page.results) || [],
      count: data?.pages[0].count || 0,
    }),
    [data],
  );

  const shouldShowAllVersions = useMemo(
    () => !showAllVersions && versionsData.count > 5,
    [showAllVersions, versionsData.count],
  );

  if (isLoading) {
    return (
      <TableRow>
        <LoadingRow columnCount={columnsOrder.length + 1} />
      </TableRow>
    );
  }

  return (
    <>
      {error || !versionsData.versions?.length ? (
        <NoDataCell>{pagesText.noVersionFound}</NoDataCell>
      ) : (
        <>
          <TableExpandedRow
            versions={versionsData.versions}
            ref={lastElementRef}
            source={source}
            columnsOrder={columnsOrder}
          />
          {shouldShowAllVersions && <TableAllVersionsRow onAllVersionsClick={onAllVersionsClick} />}
        </>
      )}
    </>
  );
});

export default ExpandedRow;
