import { memo, useCallback, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import IconButton from '../IconButton';
import Iconify from '../Iconify';
import ExpandedRow from './ExpandedRow';
import TableNameCell from './TableNameCell';
import TableRowActions from './TableRowActions';
import GenericTableCell from '../GenericTableCell';
import SecurityActions from '../SecurityActions';
import YesNoCell from './YesNoCell';
import ConditionComponent from '../ConditionComponent';
import GenericCell from './GenericCell';

/**
 * @typedef RowProps
 * @property {Object} row
 */

/**
 * Row component.
 * @param {RowProps} props - props of component
 */

const Row = ({
  row,
  source,
  activePage,
  columnData,
  ActionComponent,
  hasActions = true,
  markedRows = [],
  changeMarkedItem,
  hasSelection = false,
}) => {
  const isRowActive = markedRows[row.email];
  const [open, setOpen] = useState(false);
  const onToggleOpen = useCallback(() => setOpen(prevOpen => !prevOpen), [setOpen]);

  const onChangeMarkedItem = useCallback(() => {
    const newMarkedRows = { ...markedRows };
    if (newMarkedRows[row?.email]) {
      delete newMarkedRows[row.email];
    } else {
      newMarkedRows[row.email] = row;
    }

    changeMarkedItem(newMarkedRows);
  }, [row, changeMarkedItem, markedRows]);

  const renderCell = useCallback(
    (column, params) => {
      switch (column?.name || column) {
        case 'firstName':
          return <GenericCell cellValue={row.firstName} isRowActive={params.isRowActive} />;
        case 'environmentApplicationName':
          return (
            <TableNameCell
              key={row.environmentApplicationName}
              name={row.environmentApplicationName}
              imgSrc={row.icon}
              isRowActive={params.isRowActive}
            />
          );
        case 'executionBlockedByDynamicCodeSecurity':
          return (
            <YesNoCell
              firstCellValue={row.executionBlockedByDynamicCodeSecurity}
              secondCellValue={row.installBlockedByDynamicCodeSecurity}
            />
          );
        case 'executionTrustedByISG':
          return (
            <YesNoCell
              firstCellValue={row.executionTrustedByISG}
              secondCellValue={row.installTrustedByISG}
              firstCellDate={row.executionTrustedByISGDate}
              secondCellDate={row.installTrustedByISGDate}
            />
          );
        default:
          return (
            <GenericTableCell
              key={row[column?.name || column]}
              value={row[column?.name || column]}
              styles={column?.styles || {}}
              tooltipText={column?.tooltipText}
            />
          );
      }
    },
    [row],
  );

  return (
    <>
      <TableRow
        onClick={hasSelection ? () => onChangeMarkedItem(row) : undefined}
        sx={{
          backgroundColor: isRowActive ? '#ecefed' : '#4B4B4B',
          opacity: isRowActive ? 0.7 : 1,
          color: isRowActive ? '#000' : '#DDDDDD',
          cursor: hasSelection ? 'pointer' : 'default',
        }}>
        {columnData.map(column =>
          renderCell(column, {
            isRowActive,
          }),
        )}
        <ConditionComponent
          condition={!ActionComponent && hasActions}
          renderElement={
            <>
              {row?.versionsCount === 1 || row?.policies ? (
                <TableCell>
                  <Stack direction="row" gap={1}>
                    {!!row?.policies && (
                      <SecurityActions policies={row.policies} versionId={row.id} />
                    )}
                    <TableRowActions />
                  </Stack>
                </TableCell>
              ) : (
                <TableCell>
                  <Stack direction="row" justifyContent="center">
                    <IconButton
                      aria-label="expand row"
                      onClick={onToggleOpen}
                      selected={open}
                      color="#DDDDDD"
                      shape="rounded">
                      <Iconify
                        icon={
                          open ? 'iconamoon:arrow-up-2-duotone' : 'iconamoon:arrow-right-2-duotone'
                        }
                        color="#DDDDDD"
                        width={24}
                        height={24}
                      />
                    </IconButton>
                  </Stack>
                </TableCell>
              )}
            </>
          }
        />
        <ConditionComponent
          condition={!!ActionComponent && hasActions}
          renderElement={<ActionComponent />}
        />
      </TableRow>
      {open && (
        <ExpandedRow
          key={row.id}
          source={source}
          applicationId={row.id}
          activePage={activePage}
          columnsOrder={columnData}
        />
      )}
    </>
  );
};

export default memo(Row);
