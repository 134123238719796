import { memo } from 'react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import TableHeading from '../../../components/Table/TableHeading';
import LoadingRows from '../../../components/Table/LoadingRows';
import Row from '../../../components/Table/Row';
import NoDataCell from '../../../components/NoDataCell';
import { pagesText } from '../../../constants/pagesText';
import TablePagination from '../../../components/Table/TablePagination';
import { ROW_PER_PAGE_OPTIONS } from '../invite/constants';
import LabelDisplayedRows from '../../../components/LabelDisplayedRows';
import { generateColumnData, headings } from './constants';
import Button from '../../../components/Button';
import IconButton from '../../../components/IconButton';
import Iconify from '../../../components/Iconify';
import { colors } from '../../../theme/palette/darkPalette';
import AlertPopup from '../../../components/AlertPopup';
import AlertDescription from '../../../components/AlertDescription';

const StyledTableFooter = styled(TableFooter)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const EnvironmentsList = ({
  isLoading,
  environments,
  onChangePage,
  onChangeRowsPerPage,
  page,
  rowsPerPage,
  count,
  selectedEnvId,
  onSelectEnvironment,
  onEditEnvironment,
  onDeleteEnvironment,
  hasCustomer,
}) => (
  <Paper
    elevation={0}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      mb: 2,
    }}>
    <Table aria-label="collapsible table">
      <TableHeading headings={headings} hasFilter={false} hasHeaderCorner hasActions />
      <TableBody sx={{ backgroundColor: '#4B4B4B' }}>
        {isLoading && <LoadingRows columnCount={4} rowCount={5} />}
        {!!environments?.length &&
          environments.map(row => (
            <Row
              hasSelectOption
              key={row?.id}
              row={row}
              columnData={generateColumnData(row.description)}
              hasActions
              ActionComponent={() => (
                <TableCell>
                  <Stack direction="row" gap={2}>
                    <IconButton
                      disabled={isLoading}
                      variant="text"
                      onClick={() => onEditEnvironment(row.id)}>
                      <Iconify icon="ic:baseline-edit" color="#a2a2a2" width={24} height={24} />
                    </IconButton>
                    <IconButton
                      disabled={isLoading}
                      variant="text"
                      onClick={() => onDeleteEnvironment(row.id)}>
                      <Iconify
                        icon="material-symbols-light:delete-outline"
                        color={colors.red}
                        width={24}
                        height={24}
                      />
                    </IconButton>
                    <Button
                      variant="outlined"
                      disabled={selectedEnvId === row.id}
                      onClick={() => onSelectEnvironment(row.id)}>
                      {selectedEnvId === row.id
                        ? pagesText.environments.environmentsTable.action.selected
                        : pagesText.environments.environmentsTable.action.select}
                    </Button>
                  </Stack>
                </TableCell>
              )}
            />
          ))}
        {!isLoading && !environments?.length && !!hasCustomer && (
          <NoDataCell colSpan={5}>{pagesText.noDataFound}</NoDataCell>
        )}
      </TableBody>
    </Table>
    {!hasCustomer && !environments?.length && !isLoading && (
      <Box mt={1} mb={1}>
        <AlertPopup title={pagesText.companyList.noCompanyAlert.title}>
          <AlertDescription>{pagesText.companyList.noCompanyAlert.description}</AlertDescription>
          <AlertDescription>{pagesText.appsStarterPopup.extraDescription}</AlertDescription>
        </AlertPopup>
      </Box>
    )}
    {hasCustomer && (
      <StyledTableFooter>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          sx={{
            ml: 2,
          }}>
          <TablePagination
            rowPerPageOptions={ROW_PER_PAGE_OPTIONS}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            count={count}
            labelDisplayedRows={({ page, count }) => (
              <LabelDisplayedRows
                page={page}
                count={count || 0}
                isLoading={isLoading}
                rowsPerPage={rowsPerPage}
              />
            )}
          />
        </Stack>
      </StyledTableFooter>
    )}
  </Paper>
);

export default memo(EnvironmentsList);
