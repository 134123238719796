import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import TableNameCell from './TableNameCell';
import Skeleton from '../Skeleton';

const LoadingRow = ({ columnCount }) => (
  <>
    {Array.from({ length: columnCount }).map((item, index) => {
      if (index === 0) {
        return <TableNameCell key={index} loading={true} />;
      }

      if (columnCount - 1 === index) {
        return (
          <TableCell scope="row">
            <Stack direction="row" justifyContent="center">
              <Skeleton variant="rounded" width={40} height={40} />
            </Stack>
          </TableCell>
        );
      }

      return (
        <TableCell key={index} scope="row">
          <Skeleton animation="pulse" variant="text" height={22} />
        </TableCell>
      );
    })}
  </>
);

export default LoadingRow;
