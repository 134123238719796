import Skeleton from './Skeleton';
import Typography from '@mui/material/Typography';

export const getDisplayPageText = ({ page, count, rowsPerPage }) => {
  const applicationsCount = Math.ceil(count / rowsPerPage);

  return `Page: ${Math.min(page + 1, applicationsCount)} of ${applicationsCount}`;
};

const LabelDisplayedRows = ({ page, count, rowsPerPage, isLoading }) => (
  <>
    {isLoading ? (
      <Skeleton variant="text" animation="pulse" width={80} height={20} />
    ) : (
      <Typography>{getDisplayPageText({ page, count, rowsPerPage })}</Typography>
    )}
  </>
);

export default LabelDisplayedRows;
