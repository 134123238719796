import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CompanySectionContainer from '../../../components/CompanySectionContainer';
import Button from '../../../components/Button';
import EnvironmentsList from './EnvironmentsList';
import { pagesText } from '../../../constants/pagesText';
import useQueryData from '../../../hooks/useQueryData';
import { CUSTOMER_QUERY_KEY, USER_QUERY_KEY } from '../../../constants/query';
import { activeCustomerEnvIdSelector } from '../../../selectors/user';
import DeleteEnvironmentDialog from './DeleteEnvironmentDialog';
import useDialog from '../../../hooks/useDialog';
import useDeleteEnvironment from '../../../hooks/enviornment/useDeleteEnvironment';
import SwitchEnvironmentDialog from './SwitchEnvironmentDialog';
import environmentService from '../../../services/environment.service';
import { customerIdSelector } from '../../../selectors/customer';
import { PORTAL_ROUTE } from '../../../constants/routes';
import useQueryParams from '../../../hooks/useQueryParams';
import useEnvironments from '../../../hooks/customer/useEnvironments';
import EnvironmentFormDialog from './EnvironmentFormDialog';

export const modes = {
  initial: 'initial',
  add: 'add',
  edit: 'edit',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  lineHeight: '24px',
  color: '#fff',
  marginBottom: theme.spacing(2),
}));

const Environments = () => {
  const [mode, setMode] = useState(modes.initial);
  const [activeEnvironmentId, setActiveEnvironmentId] = useState(null);
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);

  const {
    open: openEnvironmentFormDialog,
    onOpen: onOpenEnvironmentFormDialog,
    onClose: onCloseEnvironmentFormDialog,
  } = useDialog();

  const {
    open: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDialog();

  const {
    open: isSwitchDialogOpen,
    onOpen: onSwitchDialogOpen,
    onClose: onSwitchDialogClose,
  } = useDialog();

  const onEditEnvironment = useCallback(
    selectedEnvironmentId => {
      setMode(modes.edit);
      setSelectedEnvironmentId(selectedEnvironmentId);
      onOpenEnvironmentFormDialog();
    },
    [onOpenEnvironmentFormDialog],
  );

  const onDeleteEnvironmentClick = useCallback(
    selectedEnvironmentId => {
      setSelectedEnvironmentId(selectedEnvironmentId);
      onDeleteDialogOpen();
    },
    [onDeleteDialogOpen],
  );

  const customerEnvId = useQueryData(USER_QUERY_KEY, activeCustomerEnvIdSelector);
  const customerId = useQueryData(CUSTOMER_QUERY_KEY, customerIdSelector);
  const {
    queryObject: { page, rowsPerPage },
    changeFieldInURL,
  } = useQueryParams({ defaultRowsPerPage: 5 });
  const {
    isLoading,
    data: environmentsData,
    refetch,
  } = useEnvironments({ customerId, page, rowsPerPage });
  const deleteEnvironment = useDeleteEnvironment(refetch);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onChangePage = useCallback(
    newPage => {
      changeFieldInURL([{ key: 'page', value: newPage + 1 }]);
    },
    [changeFieldInURL],
  );

  const onChangeRowsPerPage = useCallback(
    event => {
      const newRowPerPage = parseInt(event.target.value, 10);
      changeFieldInURL([
        { key: 'page', value: 1 },
        {
          key: 'limit',
          value: newRowPerPage,
        },
      ]);
    },
    [changeFieldInURL],
  );

  const selectedEnvironment = useMemo(() => {
    if (
      !environmentsData?.environments ||
      !environmentsData?.environments?.length ||
      mode === modes.add
    )
      return {};

    return environmentsData?.environments?.find(env => env.id === selectedEnvironmentId);
  }, [environmentsData?.environments, selectedEnvironmentId, mode]);

  const activeEnvironmentName = useMemo(() => {
    if (
      !activeEnvironmentId ||
      !environmentsData?.environments ||
      !environmentsData?.environments?.length
    )
      return '';

    const activeEnvironment = environmentsData?.environments?.find(
      env => env.id === activeEnvironmentId,
    );
    if (!activeEnvironment) return '';

    return activeEnvironment.name;
  }, [activeEnvironmentId, environmentsData?.environments]);

  const onAddNewEnvironment = useCallback(() => {
    setMode(modes.add);
    onOpenEnvironmentFormDialog();
  }, [onOpenEnvironmentFormDialog]);

  const onCancel = useCallback(() => {
    setMode(modes.initial);
  }, [setMode]);

  const onDelete = useCallback(() => {
    setMode(modes.initial);
    deleteEnvironment.mutate({ id: selectedEnvironmentId, force: true });
    onDeleteDialogClose();
  }, [setMode, deleteEnvironment, selectedEnvironmentId, onDeleteDialogClose]);

  const onSwitchEnvironment = useCallback(() => {
    queryClient.setQueryData(USER_QUERY_KEY, prevProps => ({
      ...prevProps,
      defaultCustomerEnvId: activeEnvironmentId,
    }));
    onSwitchDialogClose();
    environmentService
      .changeActiveEnvironment({ customerEnvId: activeEnvironmentId })
      .then(() => {
        navigate(`${PORTAL_ROUTE}/apps`);
      })
      .catch(() => {
        enqueueSnackbar(pagesText.somethingWentWrong, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, onSwitchDialogClose, queryClient, activeEnvironmentId]);

  const onSelectEnvironment = useCallback(
    environmentId => {
      setActiveEnvironmentId(environmentId);
      onSwitchDialogOpen();
    },
    [onSwitchDialogOpen],
  );

  return (
    <>
      <CompanySectionContainer>
        <Stack direction="row" justifyContent="space-between" mb={2}>
          <StyledTypography>{pagesText.environments.title}</StyledTypography>
          <Button
            variant="outlined"
            disabled={isLoading || !customerId}
            size="medium"
            onClick={onAddNewEnvironment}>
            {pagesText.environments.addButton}
          </Button>
        </Stack>
        <EnvironmentsList
          isLoading={isLoading || deleteEnvironment.isLoading}
          environments={environmentsData?.environments}
          onSelectEnvironment={onSelectEnvironment}
          selectedEnvId={customerEnvId}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          count={environmentsData?.count}
          onEditEnvironment={onEditEnvironment}
          onDeleteEnvironment={onDeleteEnvironmentClick}
          hasCustomer={!!customerId}
        />
      </CompanySectionContainer>
      <DeleteEnvironmentDialog
        open={isDeleteDialogOpen}
        onClose={onDeleteDialogClose}
        onSuccess={onDelete}
      />
      <SwitchEnvironmentDialog
        open={isSwitchDialogOpen}
        onClose={onSwitchDialogClose}
        onSuccess={onSwitchEnvironment}
        environmentName={activeEnvironmentName}
      />
      <EnvironmentFormDialog
        open={openEnvironmentFormDialog}
        onClose={onCloseEnvironmentFormDialog}
        title={
          mode === modes.add
            ? pagesText.environments.formDialog.addTitle
            : pagesText.environments.formDialog.editTitle
        }
        onExitAddMode={onCancel}
        selectedEnvironment={selectedEnvironment}
        mode={mode}
        editEnvironmentId={selectedEnvironmentId}
      />
    </>
  );
};

export default Environments;
