export const parseQueryToApiQuery = ({ filters, page, limit, searchValue }) => {
  const queryParts = [];

  if (!!filters && Object.keys(filters).length > 0) {
    for (const [filterSectionName, filterSectionData] of Object.entries(filters)) {
      let filterSectionQueryData = {};
      filterSectionData.forEach(section => {
        const [primarySection, secondarySection] = section.filter(sectionItem => sectionItem.value);
        if (primarySection && secondarySection) {
          if (filterSectionQueryData[primarySection.value]) {
            filterSectionQueryData[primarySection.value].push(secondarySection.value);
          } else {
            filterSectionQueryData[primarySection.value] = [secondarySection.value];
          }
        }
        if (section[0].order && !filterSectionQueryData.order) {
          filterSectionQueryData.order = section[0].order;
        }
      });
      Object.entries(filterSectionQueryData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          queryParts.push(`${filterSectionName}.${key}=${value.join(',')}`);
        } else if (key === 'order') {
          const filterSectionQueryDataItem = filterSectionData
            .flat()
            .find(filterSectionDataItem => filterSectionDataItem.order);
          queryParts.push(
            `orderQuery.sortBy=${
              filterSectionQueryDataItem?.sortingCellField || filterSectionName
            }`,
          );
          queryParts.push(`orderQuery.order=${value}`);
        } else {
          queryParts.push(`${filterSectionName}.${key}=${value}`);
        }
      });
    }
  }
  // Todo: change this condition check
  if (
    searchValue !== 'undefined' &&
    searchValue !== '' &&
    searchValue !== null &&
    searchValue !== undefined
  ) {
    queryParts.push(`search=${searchValue}`);
  }

  const queryString = queryParts.join('&');

  return `?Limit=${limit}&Page=${page}&${queryString}`;
};
