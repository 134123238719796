import api from './api';

const addEnvironment = ({ name, configManagerSupportId, description, location, customerId }) =>
  api.post('/customerEnvironment', {
    name,
    configManagerSupportId,
    description,
    location,
    customerId,
  });

const getEnvironments = () => api.get('/customerEnvironment');

const editEnvironment = ({ customerEnvId, configManagerSupportId, description, location, name }) =>
  api.put(`customerEnvironment/${customerEnvId}`, {
    name,
    configManagerSupportId,
    description,
    location,
  });

const changeActiveEnvironment = ({ customerEnvId }) =>
  api.put(`customerEnvironment/${customerEnvId}/activate`);

const deleteEnvironment = ({ customerEnvId, force }) =>
  api.delete(`customerEnvironment/${customerEnvId}`, null, {
    params: {
      force,
    },
  });

const EnvironmentService = {
  addEnvironment,
  getEnvironments,
  editEnvironment,
  deleteEnvironment,
  changeActiveEnvironment,
};

export default EnvironmentService;
