import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import Button from '../Button';
import { pagesText } from '../../constants/pagesText';

const TableAllVersionsRow = ({ onAllVersionsClick }) => (
  <TableRow
    sx={{
      backgroundColor: '#393939',
    }}
  >
    <TableCell colSpan={12}>
      <Stack direction="row" justifyContent="end">
        <Button
          onClick={onAllVersionsClick}
          sx={theme => ({
            backgroundColor: '#7DFF92',
            color: '#0D0D0D',
            borderRadius: '6px',
            padding: '8px 20px',
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 500,
            lineHeight: '24px',
            textTransform: 'capitalize',
          })}
        >
          {pagesText.tableAllVersionsButton}
        </Button>
      </Stack>
    </TableCell>
  </TableRow>
);

export default TableAllVersionsRow;