import api from './api';
import TokenService from './token.service';

const register = ({ email, firstName, lastName, password, companyName }) =>
  api.post('/auth/register', {
    email,
    firstName,
    lastName,
    password,
    companyName,
  });

const login = ({ email, password }) =>
  api.post('/auth/signOn', {
    email,
    password,
  });

const verify = ({ email, token }) =>
  api.post('/auth/verify', {
    email,
    token,
  });

const resendVerification = ({ email }) =>
  api.post('/auth/resendVerification', {
    email,
  });

const sendPasswordReset = ({ email }) =>
  api.post('/auth/sendPasswordReset', {
    email,
  });

const passwordResetWithToken = ({ email, token, password }) =>
  api.post('/auth/passwordResetWithToken', {
    email,
    token,
    password,
  });

const resetPassword = ({ oldPassword, newPassword, confirmPassword }) =>
  api.post('/auth/resetPassword', {
    oldPassword,
    newPassword,
    confirmNewPassword: confirmPassword,
  });

const logout = () => {
  // TODO: This needs to be updated once a refresh token revoke method
  //       is available on masterdataservice
  TokenService.removeRefreshToken();
};

const tryRefresh = ({ refreshToken }) =>
  api.post('/auth/refresh', {
    refreshToken,
  });

const AuthService = {
  register,
  resendVerification,
  login,
  logout,
  tryRefresh,
  verify,
  sendPasswordReset,
  passwordResetWithToken,
  resetPassword,
};

export default AuthService;
