import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TableFooter from '@mui/material/TableFooter';
import Box from '@mui/material/Box';
import TableHeading from './TableHeading';
import TablePagination from './TablePagination';
import Row from './Row';
import FiltersSidebar from '../Filters';
import { pagesText } from '../../constants/pagesText';
import { APPLICATIONS_QUERY_KEY, USER_QUERY_KEY } from '../../constants/query';
import ApplicationsService from '../../services/applications.service';
import useQueryData from '../../hooks/useQueryData';
import { activeCustomerEnvIdSelector } from '../../selectors/user';
import { getApplicationField } from '../../utils/application.util';
import { isObjectEmpty } from '../../utils/boolean.util';
import LoadingRow from './LoadingRows';
import useQueryParams from '../../hooks/useQueryParams';
import SearchInput from '../SearchInput';
import SearchTableWrapper from '../SearchTableWrapper';
import useDebounceHandler from '../../hooks/useDebounceHandler';
import NoDataCell from '../NoDataCell';
import { parseQueryToApiQuery } from '../../utils/query.utils';
import LabelDisplayedRows from '../LabelDisplayedRows';
import { defaultFilters, sortFieldsNameMap } from '../Filters/constants';

export const sortingMethods = {
  asc: 'asc',
  desc: 'desc',
  initial: 'initial',
};

const reversedSortMap = {
  [sortingMethods.asc]: sortingMethods.desc,
  [sortingMethods.desc]: sortingMethods.asc,
};

const Table = ({ headings, source, columnsOrder }) => {
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    queryObject: { filters = {}, searchValue: initialSearchValue, page, rowsPerPage },
    onApplySearchParams,
    onResetSearchParams,
    changeFieldInURL,
  } = useQueryParams();

  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const query = useMemo(
    () =>
      parseQueryToApiQuery({ filters, page, limit: rowsPerPage, searchValue: initialSearchValue }),
    [filters, page, rowsPerPage, initialSearchValue],
  );

  const debounceQueryHandler = useDebounceHandler((key, value) => {
    changeFieldInURL([
      { key, value },
      { key: 'page', value: 1 },
    ]);
  }, 500);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);
      debounceQueryHandler('search', event.target.value);
    },
    [debounceQueryHandler, setSearchValue],
  );

  const hasFilters = useMemo(() => {
    if (isObjectEmpty(filters)) return false;

    const filtersArray = Object.values(filters);
    const flatFilters = filtersArray.flat(3);

    return flatFilters.some(({ value }) => !!value);
  }, [filters]);

  const userEnvId = useQueryData(USER_QUERY_KEY, activeCustomerEnvIdSelector);
  const onChangePage = useCallback(
    newPage => {
      changeFieldInURL([{ key: 'page', value: newPage + 1 }]);
    },
    [changeFieldInURL],
  );

  const onChangeRowsPerPage = useCallback(
    event => {
      const newRowPerPage = parseInt(event.target.value, 10);
      changeFieldInURL([
        { key: 'page', value: 1 },
        {
          key: 'limit',
          value: newRowPerPage,
        },
      ]);
    },
    [changeFieldInURL],
  );

  const resetFieldsSort = useCallback(
    fieldName => {
      let newFilters = { ...(isObjectEmpty(filters) ? defaultFilters : filters) };
      const mappedFieldName = sortFieldsNameMap[fieldName] || fieldName;

      Object.keys(newFilters).forEach(filterField => {
        if (filterField !== mappedFieldName) {
          newFilters[filterField] = newFilters[filterField].map(singleFilterSection =>
            singleFilterSection.map(singleFilterItem => {
              delete singleFilterItem.order;
              return singleFilterItem;
            }),
          );
        }
      });

      return newFilters;
    },
    [filters],
  );

  const onSort = useCallback(
    (fieldName, sortMethod, sortingCellField) => {
      const mappedFieldName = sortFieldsNameMap[fieldName] || fieldName;

      try {
        const newFilters = resetFieldsSort(mappedFieldName);
        newFilters[mappedFieldName] = newFilters[mappedFieldName].map(
          (singleFilterSection, index) => {
            if (index === 0) {
              return singleFilterSection.map((singleFilterItem, index) => {
                if (index === 0) {
                  return {
                    ...singleFilterItem,
                    order: sortMethod,
                    sortingCellField,
                  };
                }

                return singleFilterItem;
              });
            }
            return singleFilterSection;
          },
        );

        onApplySearchParams({ filters: newFilters, page, limit: rowsPerPage, searchValue });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    },
    [onApplySearchParams, page, rowsPerPage, searchValue, enqueueSnackbar, resetFieldsSort],
  );

  const onApplyFilters = useCallback(
    filters => {
      onApplySearchParams({ filters, page: 1, limit: rowsPerPage, searchValue });
    },
    [onApplySearchParams, rowsPerPage, searchValue],
  );

  const { isLoading, data: applicationsData } = useQuery(
    [APPLICATIONS_QUERY_KEY, query, source],
    () => {
      const getApplications =
        source === 'app'
          ? ApplicationsService.getApplications
          : ApplicationsService.getSecurityApplications;

      return getApplications({
        customerEnvironmentId: userEnvId,
        query,
      });
    },
  );

  const applicationsVersionsCount = useMemo(() => {
    if (!applicationsData) return 0;

    return applicationsData?.count || 0;
  }, [applicationsData]);

  const applicationsCount = useMemo(() => {
    if (!applicationsData) return 0;

    return source === 'app'
      ? applicationsData?.applicationsCount || 0
      : applicationsData?.securitiesCount || 0;
  }, [source, applicationsData]);

  const applications = useMemo(() => {
    if (!applicationsData) return [];

    const applications = getApplicationField(
      applicationsData,
      source === 'app' ? 'applications' : 'securities',
    );
    return applications || [];
  }, [source, applicationsData]);

  const onResetSortField = useCallback(
    name => {
      try {
        const mappedFieldName = sortFieldsNameMap[name] || name;
        const filtersWithoutSort = { ...filters };
        filtersWithoutSort[mappedFieldName] = filtersWithoutSort[mappedFieldName].map(value => {
          return value.map(filterItem => {
            if (filterItem.order) {
              delete filterItem.order;
            }
            if (filterItem.sortingCellField) {
              delete filterItem.sortingCellField;
            }
            return filterItem;
          });
        });

        onApplySearchParams({ filters: filtersWithoutSort, page, limit: rowsPerPage, searchValue });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, filters, onApplySearchParams, page, rowsPerPage, searchValue],
  );

  const onSortTable = useCallback(
    (sortMethod, heading) => {
      const reversedSortOrder =
        sortMethod === sortingMethods.initial ? sortingMethods.asc : reversedSortMap[sortMethod];
      onSort(heading, reversedSortOrder);
    },
    [onSort],
  );

  const onOpenFilters = useCallback(() => {
    setIsOpenFilters(true);
  }, [setIsOpenFilters]);

  const onCloseFilters = useCallback(() => {
    setIsOpenFilters(false);
  }, [setIsOpenFilters]);

  const getListItemSortMethod = useCallback(
    heading => {
      const mappedFieldName = sortFieldsNameMap[heading] || heading;
      if (filters[mappedFieldName]) {
        const filter = filters[mappedFieldName][0].find(filter => filter.order);
        if (filter) return filter.order;
      }

      return sortingMethods.initial;
    },
    [filters],
  );

  const getCellSortMethod = useCallback(
    (heading, sortingCellField) => {
      if (filters[heading]) {
        const field = filters[heading][0].find(filter => filter.order);
        return field?.sortingCellField === sortingCellField ? field.order : sortingMethods.initial;
      }

      return sortingMethods.initial;
    },
    [filters],
  );

  const onResetSearch = useCallback(() => {
    onResetSearchParams();
    setSearchValue('');
  }, [onResetSearchParams, setSearchValue]);

  const onCellSort = useCallback(
    ({ field, sortingCellField, sortMethod }) => {
      const reversedSortOrder =
        sortMethod === sortingMethods.initial ? sortingMethods.desc : reversedSortMap[sortMethod];
      onSort(field, reversedSortOrder, sortingCellField);
    },
    [onSort],
  );

  return (
    <SearchTableWrapper>
      <SearchInput
        searchValue={searchValue}
        onChangeSearchValue={onChangeSearchValue}
        onResetSearch={onResetSearch}
        placeholder="Enter a keyword"
      />
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <TableContainer square component={Paper} elevation={0}>
          <MuiTable aria-label="collapsible table">
            <TableHeading
              hasFilters={hasFilters}
              onOpenFilters={onOpenFilters}
              headings={headings}
              onSortTable={onSortTable}
              onCellSort={onCellSort}
              onResetSortField={onResetSortField}
              getListItemSortMethod={getListItemSortMethod}
              getCellSortMethod={getCellSortMethod}
            />
            <TableBody>
              {isLoading ? (
                <LoadingRow columnCount={columnsOrder.length + 1} rowCount={rowsPerPage} />
              ) : (
                <>
                  {applications.map(row => (
                    <Row
                      key={row.id}
                      row={row}
                      source={source}
                      activePage={page}
                      columnData={columnsOrder}
                    />
                  ))}
                  {!applications.length && <NoDataCell>{pagesText.noSearchResult}</NoDataCell>}
                </>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TableFooter>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              ml: 2,
            }}>
            {!!applicationsVersionsCount ? (
              <Typography
                sx={theme => ({ fontSize: theme.typography.pxToRem(14), lineHeight: '22px' })}>
                {pagesText.totalNumberVersions} - {applicationsVersionsCount}
              </Typography>
            ) : (
              <Box />
            )}
            <TablePagination
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={rowsPerPage}
              count={applicationsCount}
              labelDisplayedRows={({ page, count }) => (
                <LabelDisplayedRows
                  page={page}
                  count={count}
                  isLoading={isLoading}
                  rowsPerPage={rowsPerPage}
                />
              )}
            />
          </Stack>
        </TableFooter>
        <FiltersSidebar
          open={isOpenFilters}
          onClose={onCloseFilters}
          onApplyFilters={onApplyFilters}
          initialQueryFilters={filters}
        />
      </Paper>
    </SearchTableWrapper>
  );
};

export default Table;
