import { useCallback } from 'react';
import Box from '@mui/material/Box';
import IconButton from '../IconButton';
import Iconify from '../Iconify';

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const handleFirstPageButtonClick = useCallback(() => {
    onPageChange(0);
  }, [onPageChange]);

  const handleLastPageButtonClick = useCallback(() => {
    onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }, [count, onPageChange, rowsPerPage]);

  const handleBackButtonClick = useCallback(() => {
    onPageChange(page - 1);
  }, [onPageChange, page]);

  const handleNextButtonClick = useCallback(() => {
    onPageChange(page === 0 ? 1 : page + 1);
  }, [onPageChange, page]);

  return (
    <Box display="flex" alignItems="center" sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        <Iconify icon="material-symbols:first-page" color="#DDDDDD" width={24} height={24} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <Iconify icon="iconamoon:arrow-left-2-duotone" color="#DDDDDD" width={24} height={24} />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        <Iconify icon="iconamoon:arrow-right-2-duotone" color="#DDDDDD" width={24} height={24} />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        <Iconify icon="material-symbols:last-page" color="#DDDDDD" width={24} height={24} />
      </IconButton>
    </Box>
  );
};

export default TablePaginationActions;
