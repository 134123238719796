import { forwardRef } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import TableNameCell from './TableNameCell';
import TableRowActions from './TableRowActions';
import GenericTableCell from '../GenericTableCell';
import SecurityActions from '../SecurityActions';
import YesNoCell from './YesNoCell';

/**
 *
 * @typedef TableExpandedRowProps
 * @property {Object} row
 * @property {"app" | "security"} source
 */

/**
 * TableExpandedRow components
 *
 * @param {TableExpandedRowProps} props - props of component
 */

const TableExpandedRow = forwardRef(
  ({ versions, source, showActions = true, columnsOrder }, ref) => {
    const renderCell = (column, version) => {
      const cellMaps = {
        environmentApplicationName: (
          <TableNameCell
            key={version.environmentApplicationName}
            name={version.environmentApplicationName}
            imgSrc={version.icon}
          />
        ),
        executionBlockedByDynamicCodeSecurity: (
          <YesNoCell
            firstCellValue={version.executionBlockedByDynamicCodeSecurity}
            secondCellValue={version.installBlockedByDynamicCodeSecurity}
          />
        ),
        executionTrustedByISG: (
          <YesNoCell
            firstCellValue={version.executionTrustedByISG}
            secondCellValue={version.installTrustedByISG}
            firstCellDate={version.executionTrustedByISGDate}
            secondCellDate={version.installTrustedByISGDate}
          />
        ),
      };

      return cellMaps[column] || <GenericTableCell key={version[column]} value={version[column]} />;
    };

    return (
      <>
        {versions.map((version, index) => (
          <TableRow
            ref={index === versions.length - 1 ? ref : null}
            key={version.id}
            sx={{
              backgroundColor: '#393939',
            }}>
            {columnsOrder.map(column => renderCell(column, version))}
            <TableCell
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: 81,
              }}>
              <Stack direction="row">
                {source === 'security' && (
                  <SecurityActions policies={version.policies} versionId={version.id} />
                )}
                {showActions && <TableRowActions />}
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  },
);

export default TableExpandedRow;
