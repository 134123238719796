import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ConditionComponent from './ConditionComponent';
import Tabs from './Tabs';
import { CUSTOMERS_QUERY_KEY } from '../constants/query';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(32),
  fontWeight: 700,
  lineHeight: '44px',
  color: theme.palette.text.primary,
}));

const Container = styled(Box)({
  height: '100%',
});

const Wrapper = styled(Box)({
  width: '100%',
  maxWidth: '952px',
  margin: 'auto',
});

const TabsPageLayout = ({ title, tabs }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  const onChangeTab = useCallback(
    (event, newValue) => {
      queryClient.refetchQueries([CUSTOMERS_QUERY_KEY]).then(() => {
        navigate(newValue);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryClient],
  );

  return (
    <Container>
      <Wrapper>
        <ConditionComponent condition={title} renderElement={<StyledTitle>{title}</StyledTitle>} />
        <Tabs value={pathname} onChange={onChangeTab} tabs={tabs} />
        <Outlet />
      </Wrapper>
    </Container>
  );
};

export default TabsPageLayout;
