import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

export const TableCellTypography = styled(Typography)(({ theme }) => ({
  color: 'inherit',
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(14),
  lineHeigth: '22px',
}));

const formatTableCell = value => {
  if (Number(value) === 0 || !!value) return value;

  return '-';
};

const GenericTableCell = memo(({ value, styles = {}, tooltipText = '' }) => (
  <TableCell
    sx={{
      color: 'inherit',
    }}>
    <Tooltip title={tooltipText} arrow disableHoverListener={!tooltipText}>
      <TableCellTypography sx={{ ...styles }}>{formatTableCell(value)}</TableCellTypography>
    </Tooltip>
  </TableCell>
));

export default memo(GenericTableCell);
