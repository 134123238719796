import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const useActiveRoute = () => {
  const location = useLocation();
  const checkIsActiveRoute = useCallback((path) => location.pathname === path,  [location.pathname]);

  return { checkIsActiveRoute }
};

export default useActiveRoute;