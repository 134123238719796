import TableRow from '@mui/material/TableRow';
import LoadingRow from './LoadingRow';

const LoadingRows = ({ columnCount, rowCount }) => {
  return Array.from({ length: rowCount }).map((row, index) => (
    <TableRow key={index}>
      <LoadingRow columnCount={columnCount} />
    </TableRow>
  ));
};

export default LoadingRows;
