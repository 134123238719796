import { pagesText } from '../../constants/pagesText';

const filterValues = {
  contain: 'contain',
  notContain: 'notContain',
  executed: 'executed',
  installed: 'installed',
  yes: true,
  no: false,
};

const defaultFilterConditionLimit = 5;

export const blockedByDynamicCodeSecurityOptions = [
  {
    type: 'menu',
    options: [
      {
        label: pagesText.filters.filter.executed,
        value: filterValues.executed,
      },
      {
        label: pagesText.filters.filter.installed,
        value: filterValues.installed,
      },
    ],
    value: filterValues.executed,
  },
  {
    type: 'menu',
    options: [
      {
        label: pagesText.filters.filter.yes,
        value: filterValues.yes,
      },
      {
        label: pagesText.filters.filter.no,
        value: filterValues.no,
      },
    ],
    value: '',
  },
];

export const trustedByISGOptions = [
  {
    type: 'menu',
    options: [
      {
        label: pagesText.filters.filter.executed,
        value: filterValues.executed,
      },
      {
        label: pagesText.filters.filter.installed,
        value: filterValues.installed,
      },
    ],
    value: filterValues.installed,
  },
  {
    type: 'menu',
    options: [
      {
        label: pagesText.filters.filter.yes,
        value: filterValues.yes,
      },
      {
        label: pagesText.filters.filter.no,
        value: filterValues.no,
      },
    ],
    value: '',
  },
];

export const nextFilterOptions = {
  [filterValues.executed]: trustedByISGOptions,
  [filterValues.installed]: blockedByDynamicCodeSecurityOptions,
};

export const sortFieldsNameMap = {
  environmentApplicationName: 'configMgrAppName',
  publisher: 'configMgrAppManufacturer',
};

export const filtersConfigs = {
  softwareCenterName: {
    filtersCount: defaultFilterConditionLimit,
  },
  configMgrAppManufacturer: {
    filtersCount: defaultFilterConditionLimit,
  },
  configMgrAppName: {
    filtersCount: defaultFilterConditionLimit,
  },
  blockedByDynamicCodeSecurity: {
    filtersCount: 2,
    shouldDisableLimitReached: true,
  },
  trustedByISG: {
    filtersCount: 2,
    shouldDisableLimitReached: true,
  },
};

export const defaultFilters = {
  softwareCenterName: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.contain,
            value: filterValues.contain,
          },
          {
            label: pagesText.filters.filter.notContain,
            value: filterValues.notContain,
          },
        ],
        value: '',
      },
      {
        type: 'input',
        value: '',
      },
    ],
  ],
  configMgrAppManufacturer: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.contain,
            value: filterValues.contain,
          },
          {
            label: pagesText.filters.filter.notContain,
            value: filterValues.notContain,
          },
        ],
        value: '',
      },
      {
        type: 'input',
        value: '',
      },
    ],
  ],
  configMgrAppName: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.contain,
            value: filterValues.contain,
          },
          {
            label: pagesText.filters.filter.notContain,
            value: filterValues.notContain,
          },
        ],
        value: '',
      },
      {
        type: 'input',
        value: '',
      },
    ],
  ],
  blockedByDynamicCodeSecurity: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.executed,
            value: filterValues.executed,
          },
          {
            label: pagesText.filters.filter.installed,
            value: filterValues.installed,
          },
        ],
        value: '',
      },
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.yes,
            value: filterValues.yes,
          },
          {
            label: pagesText.filters.filter.no,
            value: filterValues.no,
          },
        ],
        value: '',
      },
    ],
  ],
  trustedByISG: [
    [
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.executed,
            value: filterValues.executed,
          },
          {
            label: pagesText.filters.filter.installed,
            value: filterValues.installed,
          },
        ],
        value: '',
      },
      {
        type: 'menu',
        options: [
          {
            label: pagesText.filters.filter.yes,
            value: filterValues.yes,
          },
          {
            label: pagesText.filters.filter.no,
            value: filterValues.no,
          },
        ],
        value: '',
      },
    ],
  ],
};

export const sidebarList = [
  {
    title: 'software center name',
    name: 'softwareCenterName',
  },
  {
    title: 'publisher',
    name: 'configMgrAppManufacturer',
  },
  {
    title: 'Config Manager Name',
    name: 'configMgrAppName',
  },
  {
    title: pagesText.filters.blockedByDynamicCodeSecurity,
    name: 'blockedByDynamicCodeSecurity',
  },
  {
    title: pagesText.filters.trustedByISG,
    name: 'trustedByISG',
  },
];
