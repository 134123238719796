export const USER_QUERY_KEY = 'user';
export const ENVIRONEMNTS_QUERY_KEY = 'environments';
export const APPLICATIONS_QUERY_KEY = 'applications';
export const APPLICATION_VERSIONS_QUERY_KEY = 'applicationVersions';
export const DOWNLOADS_QUERY_KEY = 'downloads';
export const CUSTOMER_QUERY_KEY = 'customer';
export const LOGIN_QUERY = 'login';
export const REGISTER_QUERY = 'register';
export const RESEND_VERIFICATION_QUERY = 'resendVerification';
export const SEND_PASSWORD_RESET_QUERY = 'sendPasswordReset';
export const REFRESH_QUERY = 'refresh';
export const PASSWORD_RESET_WITH_TOKEN_QUERY = 'passwordResetWithToken';
export const CUSTOMER_SETUP_ACCOUNT_QUERY = 'customerSetupAccount';
export const COMPANY_USERS_QUERY_KEY = 'companyUsers';
export const INVITE_CUSTOMER_QUERY_KEY = 'invite';
export const CUSTOMERS_QUERY_KEY = 'customers';
export const VALIDATE_CUSTOMER_INVITE_QUERY = 'validateCustomerInvite';
