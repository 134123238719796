import { memo } from 'react';
import MuiTablePagination from '@mui/material/TablePagination';
import TablePaginationActions from './TableActions';
import { ROW_PER_PAGE_OPTIONS } from '../../constants/table';

const TablePagination = memo(
  ({
    labelDisplayedRows,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    count,
    rowPerPageOptions = ROW_PER_PAGE_OPTIONS,
  }) => {
    if (rowPerPageOptions[0] >= count) return null;

    return (
      <MuiTablePagination
        rowsPerPageOptions={rowPerPageOptions}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        ActionsComponent={TablePaginationActions}
        labelDisplayedRows={labelDisplayedRows}
        sx={{
          borderBottom: 'none',
        }}
        SelectProps={{
          sx: {
            '& .MuiSvgIcon-root': {
              color: '#fff',
            },
          },
        }}
      />
    );
  },
);

export default TablePagination;
